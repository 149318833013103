<template>
  <div class="register-container">
    <div>
      <div class="header" style="align-self: start;">
        <h2 class="primary-title" style="margin-bottom: 0">Welcome Back,</h2>
        <h4 class="primary-title" style="margin-top: 0;color: #fde383">ยินดีต้อนรับ</h4>
      </div>
      <div class="input-content" style="align-self: center;margin: 5rem 0rem;">
        <div v-if="!is_sign_in" class="input-group">
            <label class="disabled">LineID - ไลน์ไอดี (เพื่อแจ้งโปรโมชั่น)</label>
            <input style="width: 100%" class="background-panel" type="text" v-model="line_id" />
          </div>
        <div class="input-group">
          <label class="disabled">Phone Number - เบอร์โทรศัพท์</label>
          <input style="width: 100%" class="background-panel" type="text" v-model="phone_number" />
        </div>

        <div class="input-group">
          <label class="disabled">Password - รหัสผ่าน</label>
          <input style="width: 100%" class="background-panel" type="password" v-model="password" />
        </div>

        <div v-if="!is_sign_in" class="input-group">
          <label class="disabled">Bank-ID - บัญชีธนาคาร</label>
          <div style="display: flex; width: 100%">
            <select class="background-panel" v-model="bank_type" style="width: 6rem;margin-right: 1rem;">
              <option v-for="bank in bank_options" :key="bank.key">{{bank}}</option>
            </select>
            <input style="width: 100%" class="background-panel" type="text" v-model="bank_id" />
          </div>
        </div>

        <button :disabled="loading" class="highlight-primary th" @click="proceed()" style="color: #fde383;width: 85%;margin: 1rem">{{is_sign_in? 'เข้าสู่ระบบ':'สมัครสมาชิก'}}</button>
        <a v-if="is_sign_in" class="primary-title" @click="forgot_password()" style="cursor: pointer">ลืมรหัสผ่าน?</a>
      </div>
    </div>
    <footer  style="width: 100%">
      <a id="sign_in" @click="is_sign_in = true" :class="`${is_sign_in? 'primary-title active':'disabled'}`">เข้าสู่ระบบ</a>
      <a @click="is_sign_in = false" :class="`${is_sign_in? 'disabled':'primary-title active'}`">สมัครสมาชิก</a>
    </footer>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import UserService from '../../services/UserService'

export default {
  name: 'register',
  data () {
    return {
      is_sign_in: true,
      loading: false,
      phone_number: '',
      line_id: '',
      password: '',
      bank_type: '',
      bank_id: '',
      bank_options: ['KBANK', 'SCB', 'BBL', 'UOB', 'BAY', 'KTB', 'TMB', 'TBANK', 'CIMB', 'LH', 'TRUE'],
      line_poster: require('@/assets/line_poster.png'),
    }
  },
  mounted() {
    if (localStorage.getItem('user')) {
      this.$router.push('/profile')
    }
  },
  methods: {
    async proceed() {
      this.loading = true
      if (this.is_sign_in) {
        try {
          const res = await UserService.login(this.phone_number, this.password)
          if (res) {
            location.reload()
          }
        } catch (err) {
          this.loading = false
          alert('รหัสใช้งานไม่ถูกต้อง!')
        }
      } else {
        try {
          const res = await UserService.register(
            { 
              line_id: this.line_id,
              bank: `${this.bank_type}-${this.bank_id}`,
              username: this.line_id,
              phone_number: this.phone_number,
              password: this.password,
              agent_id: 1
            }
          )
          if (res) {
            location.reload()
          }
        } catch (err) {
          this.loading = false
          alert('สมัครสมาชิกล้มเหลว!')
        }
      }
    },
    forgot_password () {
      const line = 'https://line.me/ti/p/~@uday888'
      
      Swal.fire({
          html: `<a target="_blank" href="${line}" /><img style="width: 100%" src="${this.line_poster}" /></a>`,
          background: '#1c2530',
          showConfirmButton: false
      })
    }
  }
}
</script>

<style lang="less">
  .input-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .input-group {
    padding: 1rem;
    width: 100%;
  }
  .active {
    border-bottom: 1px solid #fde383;
  }
  .register-container {
    border-radius: 1rem;
    padding: 1.5rem;
    display: flex;
    margin: 1.5rem;
    margin-bottom: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #fde383;
    background-color: #1c2530;
  }
  .register-container::-webkit-scrollbar {
    display: none
  }
</style>
